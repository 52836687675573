import { useState } from 'react';
import useMemoizedFn from '../useMemoizedFn';
import { isNumber } from '../utils';
function getTargetValue(val, options = {}) {
  const {
    min,
    max
  } = options;
  let target = val;
  if (isNumber(max)) {
    target = Math.min(max, target);
  }
  if (isNumber(min)) {
    target = Math.max(min, target);
  }
  return target;
}
function useCounter(initialValue = 0, options = {}) {
  const {
    min,
    max
  } = options;
  const [current, setCurrent] = useState(() => {
    return getTargetValue(initialValue, {
      min,
      max
    });
  });
  const setValue = value => {
    setCurrent(c => {
      const target = isNumber(value) ? value : value(c);
      return getTargetValue(target, {
        max,
        min
      });
    });
  };
  const inc = (delta = 1) => {
    setValue(c => c + delta);
  };
  const dec = (delta = 1) => {
    setValue(c => c - delta);
  };
  const set = value => {
    setValue(value);
  };
  const reset = () => {
    setValue(initialValue);
  };
  return [current, {
    inc: useMemoizedFn(inc),
    dec: useMemoizedFn(dec),
    set: useMemoizedFn(set),
    reset: useMemoizedFn(reset)
  }];
}
export default useCounter;